import React from 'react';
import { motion } from 'framer-motion';
import catstamp from '../assets/imgs/cat-stamppng.png'
import pl from '../assets/imgs/connectors/pl.png'
import pr from '../assets/imgs/connectors/pr.png'
import catface from '../assets/imgs/cathead.png'
import dojoswap from '../assets/imgs/logos/uniswap-uni-logo 1.svg'
import topbanner from '../assets/imgs/top-banner.png'
export const Toparea = ({ isMobile }) => {


    const textVariants = {
        initial: {
            x: "-180%",
        },
        animate: {
            x: '0%',
            transition: {
                duration: 50,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'loop',
            },
        },
    };

    const bdtextVariants = {
        initial: {
            x: "0%",
        },
        animate: {
            x: "-100%",
            transition: {
                duration: 500,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'loop',
            },
        },
    };

    const bounceVariants = {
        animate: {
            scale: [1, 0.9, 1],
            transition: {
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "mirror",
            },
        },
    };

    const containerStyles = {
        overflow: 'hidden',
        position: 'relative',
    };



    return (
        <div className='toparea'>
            <div className="white-area black">

                <div className="area1 uptx">
                    <motion.div className="rolling-text black"
                        variants={textVariants} initial="initial" animate="animate"
                    >
                        {Array.from({ length: 20 }).map((_, index) => (
                            <React.Fragment key={index}>
                                <p className="tf">~ ginger ~</p>
                                <p className="vt uptx">
                                    factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER
                                </p>
                                <p className="tf">~ ginger ~</p>
                                <p className="vt uptx">
                                    welcome dear visitor
                                </p>
                            </React.Fragment>
                        ))}
                    </motion.div>
                </div>

                <div className="area2">
                    <div className="left-side vt">
                        <p>Network : Inj</p>
                        <p>Symbol  &nbsp;: Ginger</p>
                        {isMobile ?
                            <p>Address : <a href='https://explorer.injective.network/asset/?denom=factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER&tokenType=tokenFactory' target='_blank' rel='noreferrer' className="blk">click here</a></p>
                            :
                            <p>Address : <span className="shrink">factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER</span></p>

                        }

                        <img src={catstamp} alt="catstamp" className='catstamp' />
                    </div>
                    <div className="right-side gar">
                        <p className="txt">

                            Welcome to Ginger, the $INJ token inspired by Eric Chen's cat. We are the strongest and most welcoming community on Injective Join us in exploring a world of endless possibilities with ginger                        </p>
                    </div>
                </div>
            </div>


            <div className="img-area" style={containerStyles}>
                <img src={topbanner} alt="topbanner" className='topbanner' />

                <div className="rotatingtxts">
                    <p className="uni white">  {Array.from({ length: 100 }).map((_, index) => (
                        <React.Fragment key={index}>
                            <span className="word">ginger</span>
                        </React.Fragment>
                    ))}</p>
                    <motion.p className="uni pink" variants={bdtextVariants} initial="initial" animate="animate"
                    >
                        {Array.from({ length: 100 }).map((_, index) => (
                            <React.Fragment key={index}>
                                <span className="word">ginger</span>
                            </React.Fragment>
                        ))}
                    </motion.p>
                    <p className="uni white">  {Array.from({ length: 100 }).map((_, index) => (
                        <React.Fragment key={index}>
                            <span className="word">ginger</span>
                        </React.Fragment>
                    ))}</p>
                </div>

                <div className="center-cat-face-area">
                    <img src={pl} alt="pl" className='pl' />
                    <motion.img
                        src={catface}
                        alt="catface"
                        className='catface'
                        variants={bounceVariants}
                        animate="animate"
                    />
                    <img src={pr} alt="pr" className='pr' />
                </div>

                <a href="https://dojo.trading/swap?type=swap&from=inj&to=factory%2Finj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9%2FGINGER" target="_blank" rel='noreferrer'>
                    <div className="buy-btn vt uptx darkenhov">Buy On Dojoswap <img src={dojoswap} alt="dojoswap" className='uniswaplogo' /></div>
                </a>
            </div>
        </div>
    );
}
