import React, { useState, useEffect } from 'react';
import gingerlogo from '../assets/imgs/logos/gingerlogo.png'
import { FaRectangleXmark } from "react-icons/fa6";
import { HiOutlineMenuAlt3 } from "react-icons/hi";

import { IconContext } from 'react-icons';
import { motion, AnimatePresence } from 'framer-motion'
import { FaTelegram } from "react-icons/fa";

export const Navbar = () => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        updateIsMobile();


        window.addEventListener('resize', updateIsMobile);

        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };


    }, []);





    const [navbarPopup, setNavbarpopup] = useState(false);


    return (
        <div className='navbar blk vt'>
            <img src={gingerlogo} alt="gingerlogo" className='gingerlogo' />
            <div className="navarea">
                <a href='https://dojo.trading/swap?type=swap&from=inj&to=factory%2Finj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9%2FGINGER' target="_blank" rel='noreferrer' className='nav-item'>dojoswap</a>
                <a href='https://coinhall.org/injective/inj1uugfny4cqxq0jg9vd6svyunhmdlk2s6wjdftj8' target="_blank" rel='noreferrer' className='nav-item'>coinhall</a>
                <a href='https://t.me/ginger_raids' target="_blank" rel='noreferrer' className='nav-item'>raids</a>
                <a href='https://twitter.com/Ginger_INJ' target="_blank" rel='noreferrer' className='nav-item'>twitter</a>
            </div>
            <a href="https://t.me/ginger_inj" target="_blank" rel='noreferrer'
            >
                <div className="community-btn" >
                    <FaTelegram />
                    telegram
                </div>
            </a>

            <IconContext.Provider value={{ size: "2rem", color: "#ffe8dd" }}>

                {isMobile && <HiOutlineMenuAlt3 onClick={() => setNavbarpopup(true)} />}

            </IconContext.Provider>

            <AnimatePresence>

                {navbarPopup &&

                    <motion.div className="mobilenav vt"
                        initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
                    >
                        <IconContext.Provider value={{ size: "2.1rem", color: "#ffe8dd" }}>

                            <FaRectangleXmark onClick={() => setNavbarpopup(false)} className='closeico' />
                        </IconContext.Provider>

                        <img src={gingerlogo} alt="gingerlogo" className='gingerlogo-nav' onClick={() => setNavbarpopup(false)} />
                        <div className="navarea">
                            <a href='https://dojo.trading/swap?type=swap&from=inj&to=factory%2Finj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9%2FGINGER' target="_blank" rel='noreferrer' className='nav-item'>dojoswap</a>
                            <a href='https://coinhall.org/injective/inj1uugfny4cqxq0jg9vd6svyunhmdlk2s6wjdftj8' target="_blank" rel='noreferrer' className='nav-item'>coinhall</a>
                            <a href='https://t.me/ginger_raids' target="_blank" rel='noreferrer' className='nav-item'>raids</a>
                            <a href='https://twitter.com/Ginger_INJ' target="_blank" rel='noreferrer' className='nav-item'>twitter</a>
                        </div>

                        <a href="https://t.me/ginger_inj" target="_blank" rel='noreferrer'
                        >
                            <div className="community-btn" >
                                <FaTelegram />
                                telegram
                            </div>
                        </a>
                    </motion.div>

                }
            </AnimatePresence>

        </div>
    )
}
