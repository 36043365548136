import React from 'react'
import catcar from '../assets/imgs/cat-drive.png'
import { motion, AnimatePresence } from 'framer-motion';

export const Loadscreen = ({ loading, isMobile }) => {
    return (
        <AnimatePresence>
            {loading &&
                <motion.div className='loadingscreen' key="modal"
                    initial={{ opacity: 1, x: 0 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 1, x: "100%", transition: { type: 'spring', duration: 4 } }}

                >
                    <img src={catcar} className="catcar" alt='catcar' />
                    <span className="loader"></span>
                    {!isMobile ?
                        <div className="vt white txt">The Ginger Token Official Website</div>
                        : 
                        //<div className="vt white txt">The Ginger Team has been working hard on the mobile update, it should be live in a day <br></br>
                          //  in the meantime we apologize for making you wait.</div>
                          <div className="vt white txt">The Ginger Token Official Website</div>

                    }
                </motion.div>
            }

        </AnimatePresence>

    )
}
