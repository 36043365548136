import React from 'react'
import { FaTelegramPlane } from "react-icons/fa";
import { FaMagnifyingGlassDollar, FaXTwitter } from "react-icons/fa6";
import arcade from '../assets/imgs/ginger-arcade.png';
import curveblu from '../assets/imgs/connectors/curveblu.png'
import logo from '../assets/imgs/cathead.png'
export const Footer = ({ isMobile }) => {
    return (
        <>
            <img src={curveblu} alt="curveblu" className='curveblu-footer' />
            <div className='footer'>

                <img src={arcade} alt="arcade" className='arcade' />
                <div className="centre-icons">
                    <a href='https://explorer.injective.network/asset/?denom=factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER&tokenType=tokenFactory' className="icon"
                        target='_blank' rel='noreferrer'><FaMagnifyingGlassDollar /></a>
                    <a href='https://twitter.com/Ginger_INJ ' className="icon" target="_blank" rel='noreferrer'><FaXTwitter /></a>
                    <a href='https://t.me/ginger_inj' className="icon" target='_blank' rel='noreferrer'><FaTelegramPlane /></a>

                </div>

                <div className="about-area">
                    <img src={logo} alt="logo" className='logo' />
                    <div className="title-txt vt">©2024 GINGER TOKEN
                    </div>
                    {isMobile ?
                        <div className="sub-txt vt">
                        Ginger is a meme coin on INJ. <br />
                        All rights reserved
                    </div>
                     :
                        <div className="sub-txt gar">
                            Ginger is a meme coin on INJ. <br />
                            All rights reserved
                        </div>
                    }

                </div>
            </div>
        </>

    )
}
