import React from 'react'
import curveblu from '../assets/imgs/connectors/curveblu.png'
import catfacedet from '../assets/imgs/catheaddet.png'
import catball from '../assets/imgs/cat-ball.png'
import catcar from '../assets/imgs/cat-drive.png'
import painting from '../assets/imgs/painting.png'
import { motion } from 'framer-motion'
import { FaCross, FaHeart } from "react-icons/fa";
import { IconContext } from 'react-icons'
import { GiBookmarklet } from 'react-icons/gi'

export const Aboutarea = ({ isMobile }) => {


    const carAnimation = {
        drive: {

            x: [-650, 650, 650, -650, -650, -650],
            y: [300, 300, -300, -300, 300, 300],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };

    const carAnimationMobile = {
        drive: {

            x: [-140, 140, -140],
            y: [510],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 4,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 4,
                    ease: "linear"
                }
            }
        }
    };

    return (<>
        <img src={curveblu} alt='curveblu' className="curveblu" />


        <div className='aboutarea white'>

            <div className="abt tf white">about ginger</div>
            {
                isMobile ?

                    <div className="text-cnt uni mobile-text-cnt">
                        <div className="line1 uptx">
                            <p className="tf white"><span className="blue">G</span>inger</p><img src={catfacedet} alt="catfacedet" className='catfacedet' />
                        </div>

                        <div className="line2 uptx">IS AN INJ TOKEN BASED ON Eric Chen's Favorite cat</div>
                        <div className="line3 uptx">ERIC CHEN IS THE <span className="vt">FOUNDER</span>
                            OF</div>


                        <img src={painting} alt="painting" className='mid-painting' />
           
                        <IconContext.Provider value={{ size: "3rem" }}>
                            <div className="line4 uptx"><span className="vt">INJ ecosystem</span>
                                <FaCross /></div>
                            <div className="line4b uptx">
                                AND THIS PROJECT IS <br /> ONE OF THE FEW THAT IS</div>
                            <div className="line5 uptx"> <div className="supcnt vt"> SUPPORTED BY THE FOUNDER <FaHeart size={"2.2rem"} fill='#ee2d1f' /></div></div>

                            <div className="miscline gar">
                                we as a team completely understand the various anxieties and
                                confusion a user has when onboarding a new ecosystem like inj, so we have written a onboarding guide below just for you
                            </div>
                            <GiBookmarklet size={"3.5rem"} className='bookico' />
                        </IconContext.Provider>
                        <img src={painting} alt="painting" className='painting' />

                        <motion.img src={catcar} alt="catcat" className='catcar'
                            animate="drive"
                            variants={carAnimationMobile}
                            style={{
                                position: 'absolute',
                                top: 'initial Y position',
                                left: 'initial X position',
                            }}
                        />
                    </div>

                    :
                    <div className="text-cnt uni">
                        <div className="line1 uptx"><p className="tf white"><span className="blue">G</span>inger</p><img src={catfacedet} alt="catfacedet" className='catfacedet' />IS AN INJ TOKEN BASED ON
                        </div>

                        <div className="twoduo">
                            <div className="one">
                                <div className="line2 uptx">Eric Chen's Favorite</div>
                                <div className="line3 uptx">ERIC CHEN IS THE <span className="vt">FOUNDER</span>
                                    OF</div>
                            </div>

                            <div className="two uptx">
                                <img src={catball} alt="ball" className='catball' />
                                <div className="txts uni">
                                    <p className="cat">cat</p>
                                    <p className="the">the</p>
                                </div>
                            </div>
                        </div>
                        <IconContext.Provider value={{ size: "3rem" }}>
                            <div className="line4 uptx"><span className="vt">INJ ecosystem</span>
                                <FaCross />AND THIS PROJECT</div>
                            <div className="line5 uptx">IS ONE OF THE FEW <div className="supcnt vt"> SUPPORTED BY THE FOUNDER <FaHeart size={"2.2rem"} fill='#ee2d1f' /></div></div>

                            <div className="miscline gar">
                                we as a team completely understand the various anxieties and
                                confusion a user has when onboarding a new ecosystem like inj, so we have written a onboarding guide below just for you
                            </div>
                            <GiBookmarklet size={"3.5rem"} className='bookico' />
                        </IconContext.Provider>
                        <img src={painting} alt="painting" className='painting' />

                        <motion.img src={catcar} alt="catcat" className='catcar'
                            animate="drive"
                            variants={carAnimation}
                            style={{
                                position: 'absolute',
                                top: 'initial Y position',
                                left: 'initial X position',
                            }}
                        />
                    </div>

            }


        </div>
    </>

    )
}
