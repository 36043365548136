import React from 'react'
import catback from '../assets/imgs/ginger-back.png'
import { motion } from 'framer-motion'

export const OnBoard = () => {

    const textVariants = {
        initial: {
            x: "-100%",
        },
        animate: {
            x: '0%',
            transition: {
                duration: 50,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'loop',
            },
        },
    };



    return (
        <div className='onboard'>
            <div className="area">


                <motion.div className="rolling-text black" variants={textVariants} initial="initial" animate="animate">
                    {Array.from({ length: 20 }).map((_, index) => (
                        <React.Fragment key={index}>
                            <p className="tf">~ ginger ~</p>
                            <p className="vt uptx">
                                factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER
                            </p>
                            <p className="tf">~ ginger ~</p>
                            <p className="vt uptx">
                                onboarding guide below                        </p>
                        </React.Fragment>
                    ))}
                </motion.div>
            </div>

            <div className="tog">
                <p className="tf uptx sidetxt">OnBoarding Guide</p>

                <div className="onboard-cnt vt white">
                    <div className="title-area blk">
                        <img src={catback} alt="catback" className='catback' />
                        Are you not on the INJ ecosystem yet?
                    </div>
                    <div className="below-title">here is a guide to help you get started</div>

                    <ul className="content-areaonb">
                        <li className="onstep">1. <span className="circ">Install Leap Wallet:</span> Go to the Chrome Web store, find Leap Cosmos Wallet, and add it to your browser.</li>
                        <li className="onstep">2. <span className="blk">Create New Account:</span> Open Leap, hit 'Create new wallet', and jot down your mnemonic seed phrase on paper.<span className="blk"> DO NOT SHARE THIS WITH ANYONE EVER</span></li>
                        <li className="onstep">3. Switch to Injective: In the top right corner of the leap dropdown switch from 'Cosmos Hub' to 'Injective'.</li>
                        <li className="onstep">4.<span className="blk">Fund Your Wallet:</span> Copy your 'inj...' address from Leap. Use Binance, Kucoin, or Kraken to send INJ to your wallet.</li>
                        <li className="onstep">5. Bridging for ERC-20 INJ: Bridging is also an option. If you have ERC-20 INJ, go to Injective Hub's bridging page. Set an allowance, and use Metamask or your wallet of choice to bridge tokens to Injective's mainnet.</li>
                    </ul>

                    <div className="circ">And now you're all set with your INJ wallet!</div>
                    <p className="leaptxt gar">thank you for reading :)</p>
                </div>
            </div>

        </div>
    )
}
