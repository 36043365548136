import { useState, useEffect } from "react";
import { Aboutarea } from "./components/Aboutarea";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Toparea } from "./components/Toparea";
import { OnBoard } from "./components/OnBoard";
import { Loadscreen } from "./components/Loadscreen";

function App() {

  const [isMobile, setIsMobile] = useState(false);

  // Handle window resize
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])


  return (
    <div className="App">
      <Loadscreen loading={loading} isMobile={isMobile} />
      <Navbar />
      <Toparea isMobile={isMobile} />
      <Aboutarea isMobile={isMobile} />
      <OnBoard />
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default App;
